<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>화상 수업시간 선택</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                  <!-- 시리즈 학습일정 -->
                  <div style="width:100%;">
                    <div class="mypage_top_btn_wrap">
                      <!-- 포인트 잔액 -->
                      <div class="mypage_bank_btn_wrap">
                      <div class="mypage_bank_btn">
                        <div class="mypage_bank_h4_title">
                          <h4>포인트 잔액</h4>
                        </div>
                        <div class="mypage_bank_num">200,000</div>
                      </div>
                      </div>
                      <!-- //포인트 잔액 -->
                      <!-- 포인트 충전 -->
                      <div class="mypage_charge_btn_wrap">
                        <div class="mypage_charge_btn">
                          <div class="mypage_charge_h4title">포인트 충전</div>
                        </div>
                      </div>
                      <!-- //포인트 충전 -->
                    </div>
                    <!-- 포인트 사용내역 -->
                    <div class="mypage_use_list">
                      <div class="mypage_use_title">
                        포인트 사용내역
                      </div>
                      <ul>
                        <li>
                          <a class="mypage_use_list_text" href="#">s5 수강료</a>
                          <div class="mypage_use_list_btn">05.15</div>
                        </li>
                        <li>
                          <a class="mypage_use_list_text" href="#">s5 수강료</a>
                          <div class="mypage_use_list_btn">05.15</div>
                        </li>
                        <li>
                          <a class="mypage_use_list_text" href="#">s5 수강료</a>
                          <div class="mypage_use_list_btn">05.15</div>
                        </li>
                      </ul>
                  </div>
                  </div>
                  <!--// 포인트 사용내역 -->
                  <!-- 충전금액 -->
                  <div class="point_wrap">
                    <dl>
                      <dt>결재 금액</dt>
                      <dd>200,000</dd>
                    </dl>
                    <a href="#">
                      <div class="program_point_btn">
                        <p class="point_charge_btn_title">충전</p>
                      <div class="record_btn_click_area">
                        <img src="image/sub/study_record_btn04.png" alt="클릭 아이콘">
                      </div>
                      </div>
                    </a>
                  </div>
                  <!-- //충전금액 -->
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'program' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Program.Point',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  }
}
</script>

<style scoped>

</style>
